<template>
  <div class="parents">
    <el-tree
      class="left"
      :data="treedata"
      :props="defaultProps"
      @node-click="handleNodeClick"
      default-expand-all
    ></el-tree>

    <div class="right">
      <div class="top">
        <el-input
          class="input"
          v-model="form.name"
          placeholder="请输入用户名称"
        ></el-input>
        <el-button class="leftgap" type="primary" @click="loadArticles()"
          >查询</el-button
        >
        <el-button class="leftgap" type="primary" @click="addposition()"
          >新增</el-button
        >
        <el-button class="leftgap" type="primary" @click="research()"
          >重置</el-button
        >
      </div>
      <div class="bottom">
        <el-table
          :data="tabledata"
          stripe
          class="list-table"
          height="100%"
          style="width: 100%"
          size="mini"
        >
          <el-table-column prop="deptName" label="隶属部门"> </el-table-column>
          <el-table-column prop="name" label="职位名称"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <div>
                <el-tooltip
                  content="編輯"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="editposti(scope.row)"
                    circle
                    icon="el-icon-s-tools"
                    type="primary"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  content="删除"
                  placement="top"
                  :open-delay="0"
                  :enterable="false"
                >
                  <el-button
                    size="mini"
                    @click="showdelect(scope.row)"
                    circle
                    icon="el-icon-delete"
                    type="primary"
                  ></el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          layout="prev, pager, next"
          background
          :total="totalCount"
          :page-size="form.size"
          :current-page.sync="form.page"
          @current-change="onCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      class="form-panel"
      title="你确定删除此岗位吗"
      :visible.sync="delectalartshow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="300px"
    >
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delectalartshow = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="delectdept"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑、添加 -->
    <el-dialog
      class="form-panel"
      :title="panelTitle"
      :visible.sync="showadd"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form
        ref="form-panel"
        :model="formPanelData"
        class="edit-form"
        label-width="110px"
        label-position="right"
        :rules="formPanelOptionsRules"
        @submit.native.prevent
      >
        <el-form-item
          v-for="(item, index) in formPanelOptions"
          :key="index + item.key"
          class="edit-form-item"
          :label="item.label + '：'"
          :prop="item.key"
          size="small"
        >
          <div v-if="item.type === 'text'" class="edit-form-text">
            <el-input
              :id="'edit' + item.key + index"
              v-model="formPanelData[item.key]"
              :type="item.type"
              :placeholder="'请输入' + item.label"
              clearable
              size="small"
              autocomplete="off"
            />
          </div>
          <div v-if="item.type === 'cascade'" class="edit-form-select">
            <el-cascader
              style="width: 100%"
              :id="'edit' + item.key + index"
              v-model="formPanelData[item.key]"
              :type="item.type"
              :options="treedata"
              :props="{ checkStrictly: true, label: 'name', value: 'deptId' }"
              :placeholder="'请选择' + item.label"
              clearable
              @change="handleSelectDept"
            />
          </div>
        </el-form-item>
        <el-form-item label="是否该部门主管">
          <div>
            <el-radio v-model="formPanelData.managerFlag " :label="true">是</el-radio>
            <el-radio v-model="formPanelData.managerFlag " :label="false">否</el-radio>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitFormData"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getdeptlisturl,
  getpositionlisturl,
  addpositionurl,
  delpositionturl,
  editpositionturl,
} from "@/api/jurisdiction";
import { translateDataToTree } from "@/utils/setMethods";
export default {
  data() {
    return {
      delectalartshow: false,
      panelTitle: "",
      formPanelData: {},
      delectdeptid: null,
      formPanelOptions: [
        { type: "text", label: "职位编号", key: "code" },
        { type: "text", label: "职位名称", key: "name" },
        { type: "cascade", label: "所属部门", key: "deptId" },
        { type: "text", label: "备注", key: "remark" },
      ],
      formPanelOptionsRules: {
        name: [{ required: true, message: "请输入职位名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入职位编号", trigger: "blur" }],
        deptId: [
          { required: true, message: "请选择所属部门", trigger: "blur" },
        ],
      },

      form: {
        name: null,
        size: 20,
        page: 1,
        deptId: 1,
      },
      treedata: [],
      totalCount: 0,
      tabledata: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      showadd: false,
    };
  },
  mounted() {
    this.getdeptlist();
    this.loadArticles(1);
  },
  methods: {
    editposti(row, column, event) {
      this.formPanelData = row;
      this.panelTitle = "职位编辑";
      this.showadd = true;
      
    },

    addposition() {
      this.panelTitle = "新增职位";
      this.showadd = true;
       this.formPanelData.managerFlag = false
    },
    research() {
      this.form.name = null;
      this.loadArticles();
    },
    showdelect(row, column, event) {
      this.delectdeptid = row.postId;
      this.delectalartshow = true;
    },
    async delectdept() {
      try {
        const res = await delpositionturl({ postId: this.delectdeptid });
        if (res.data.code === 200) {
          this.$message({
            message: "刪除成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.delectalartshow = false;
        this.research();
      }
    },
    async submitFormData() {
      try {
        let res;
        if (this.panelTitle === "新增职位") {
          res = await addpositionurl(this.formPanelData);
        } else if (this.panelTitle === "职位编辑") {
          res = await editpositionturl(this.formPanelData);
        }
        if (res.data.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showadd = false;
        this.formPanelData = {};
        this.research();
      }
    },
    async getdeptlist() {
      try {
        const res = await getdeptlisturl();
        if (res.data.code === 200) {
          this.treedata = translateDataToTree(res.data.data);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },

    handleSelectDept(val) {
      if (val) {
        this.formPanelData.deptId = val[val.length - 1];
      }
    },
    handleNodeClick(data) {
      console.log(data);
      this.loadArticles(null, data.deptId);
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1, deptId = null) {
      this.form.page = page;
      this.form.deptId = deptId;
      // 展示加载中 loading
      this.loading = true;
      try {
        const res = await getpositionlisturl(this.form);
        if (res.data.code === 200) {
          this.tabledata = res.data.data.record;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.parents {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .left {
    border-style: solid;
    border-width: 1px;
    border-color: #6f716a;
    background-color: white;
    width: 15%;
    height: 100%;
  }
  .right {
    background-color: white;
    border-color: #6f716a;
    border-style: solid;
    border-width: 1px;
    width: 83%;
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    .top {
      padding-top: 10px;
      padding-bottom: 10px;
      border-width: 0px, 0px, 0px, 0px;
      border-bottom-color: #6f716a;
      border-bottom-style: solid;
      .input {
        height: 20%;
        width: 200px;
        margin-right: 10px;
      }
    }
    .bottom {
      height: 80%;
    }
  }
}
.edit-form {
  display: flex;
  flex-wrap: wrap;
  .edit-form-text {
    width: 260px;
  }
  .edit-form-select {
    width: 260px;
  }
  .edit-form-switch {
    width: 280px;
  }
  .deptInfos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px dotted #ddd;
  }
  .regulation-btn {
    z-index: 1;
    span {
      cursor: pointer;
      font-size: 20px;
      color: #20a0ff;
      &:first-child {
        margin-left: -50px;
        margin-right: 10px;
      }
    }
  }
}
</style>
